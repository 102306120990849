import { getBaseURL } from '../../env'
import { SortItemKey, SortState } from '../../util/hooks/api/useContentReport'
import { SORT_ORDER, SortOrder } from '../../util/sortBy'
import { SORT_ICONS } from '../common/SortButton'

export const GRID_MIN_WIDTH = 942
export const GRID_ITEM_HEIGHT = 36
export const REGISTER_FOOTER_HEIGHT = 48
export const GRID_TEMPLATE_COLUMNS = '108px minmax(134px, 1fr) 155px 106px 72px 72px 72px 106px 72px 72px'
export const GRID_HEADER_ROWS_MIN_HEIGHT = '107px'
export const GRID_HEADER_ROWS_CHILD_HEIGHT = '34px 36px 36px'

export const LOWEST_RANK_TO_SHOW = 10

export const CONTENT_GROUP_REPORT_HOWTO_MOVIE_URL = `${getBaseURL()}/media/movies/contentGroupReportHowTo.mp4`

export const extractContentReportSortValue = (
  sortState: SortState,
): {
  sortKey: SortItemKey | null
  order: SortOrder
} => {
  const sortObj = Object.entries(sortState).find(([_, value]) => value !== 'none')
  if (!sortObj) return { sortKey: null, order: SORT_ORDER.DESC }

  const [sortKey, sortIcon] = sortObj
  return {
    sortKey: sortKey as SortItemKey,
    order: sortIcon === SORT_ICONS.DOWN ? SORT_ORDER.DESC : SORT_ORDER.ASC,
  }
}

// TODO: pages.ContentReportの記述は極力こちらに移動する。（余力ある時でOK）
