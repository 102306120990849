import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { GlobalStyle } from './GlobalStyle'
import { Routes } from './Routes'
import { GlobalContextWrapper } from './GlobalState'
import * as Sentry from '@sentry/browser'
import { store } from './redux/Store'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LocationProvider } from '@gatsbyjs/reach-router'
import { Toast } from './components/common/Toast'
import './chartConfig'

// 本番環境とステージング環境のみ
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  Sentry.init({
    dsn: 'https://692823bc089e4d24a4ae9c420a6b196b@o23946.ingest.sentry.io/1727067',
    environment: process.env.NODE_ENV,
  })
}

async function enableMswMocking() {
  if (process.env.API_ENV !== 'mock') return
  const { worker } = await import('./mocks/setupMockBrowserWorker')
  return worker.start()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // errorでstringがセットされる場合は入力値エラーのため、リトライしない
        // see. src/util/request.ts
        if (typeof error === 'string' || process.env.API_ENV === 'mock') return false
        return failureCount <= 3
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
})

function App() {
  return (
    <LocationProvider>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <GlobalContextWrapper>
          <Provider store={store}>
            <Routes />
            <Toast />
          </Provider>
        </GlobalContextWrapper>
      </QueryClientProvider>
    </LocationProvider>
  )
}

enableMswMocking().then(() => {
  ReactDOM.render(<App />, document.getElementById('app'))
})
