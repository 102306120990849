import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { ContentLocation } from '../../../util/hooks/api/useContentReport'
import { getColorByRateGrade, RateGrade } from '../../../util/getConversionRateGrade'
import { CAPTURE_VIEW, CaptureView, useCaptureView } from '../../../util/hooks/useCaptureView'

interface Props {
  readonly location: ContentLocation
  readonly pageCaptureAreaWidth: number | null
}

export function CaptureGroupNumber({ location }: Props) {
  const { captureView } = useCaptureView()
  return (
    <Container captureView={captureView} goalGrade={location.goalGrade}>
      {location.linkContentId}
    </Container>
  )
}

type ContainerProps = {
  readonly captureView: CaptureView
  readonly goalGrade: RateGrade
}
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 48px;
  font-size: 22px;
  font-weight: bold;
  background-color: ${({ captureView, goalGrade }) =>
    captureView === CAPTURE_VIEW.WITH_GOAL_RATE ? getColorByRateGrade(goalGrade) : colors.contentOrange.orange5};
  color: ${colors.white};
`
