import { colors } from '../styleConstants'

const RATE_GRADE = {
  VERY_POOR: 0,
  POOR: 0.5,
  GOOD: 1,
  VERY_GOOD: 2,
} as const

export type RateGrade = typeof RATE_GRADE[keyof typeof RATE_GRADE]

// コンテンツレポートの各コンテンツのゴール貢献率と全体ゴール貢献率を比較し、4段階評価を返す
export const getConversionRateGrade = (itemRate: number, conversionsRate: number): RateGrade => {
  if (conversionsRate === 0) {
    return RATE_GRADE.VERY_POOR
  } else if (itemRate >= conversionsRate * RATE_GRADE.VERY_GOOD) {
    return RATE_GRADE.VERY_GOOD
  } else if (itemRate >= conversionsRate * RATE_GRADE.GOOD) {
    return RATE_GRADE.GOOD
  } else if (itemRate >= conversionsRate * RATE_GRADE.POOR) {
    return RATE_GRADE.POOR
  } else {
    return RATE_GRADE.VERY_POOR
  }
}

export const getColorByRateGrade = (grade: RateGrade) => {
  return grade === RATE_GRADE.POOR || grade === RATE_GRADE.VERY_POOR ? colors.contentBlue.blue9 : colors.red
}

export const getFontWeightByRateGrade = (grade: RateGrade) => {
  return grade === RATE_GRADE.VERY_POOR || grade === RATE_GRADE.VERY_GOOD ? 'bold' : 'normal'
}

export const getBorderWidthByRateGrade = (grade: RateGrade) => {
  return grade === RATE_GRADE.VERY_POOR || grade === RATE_GRADE.VERY_GOOD ? '4px' : '2px'
}
