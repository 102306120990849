import * as React from 'react'
import styled from 'styled-components'
import { Crown } from '@styled-icons/fa-solid/Crown'
import { colors } from '../../styleConstants'

interface Props {
  readonly rank: number
  readonly isLarge: boolean
  readonly backgroundColor?: string
}

export function RankLabel({ rank, isLarge, backgroundColor }: Props) {
  return (
    <RankBox isLarge={isLarge} backgroundColor={backgroundColor}>
      <StyledCrown size={16} color={`${colors.red}`} />
      <RankText isLarge={isLarge}>{rank}</RankText>
    </RankBox>
  )
}

const RankBox = styled.div<{ isLarge: boolean; backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isLarge }) => (isLarge ? '54px' : '40px')};
  ${({ backgroundColor }) => backgroundColor && `background ${backgroundColor}`}
`

const StyledCrown = styled(Crown)`
  width: 14px;
  margin: 0 0 0 4px;
`
const RankText = styled.div<{ isLarge: boolean }>`
  color: ${colors.black2};
  font-size: ${({ isLarge }) => (isLarge ? '22px' : '12px')};
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  margin: 0 auto;
`
