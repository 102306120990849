const HELP_DOMAIN = 'https://www.uncovertruth.co.jp'
const HELP_URL_TOP = `${HELP_DOMAIN}/service/ca/help`
export const HELP_LINKS = {
  TOP: HELP_URL_TOP,
  CONTENT_REPORT_AGGREGATION_PERIOD: `${HELP_URL_TOP}/functionguide/report/#content-information-area`,
  CONTENT_REPORT_VIEW_COUNT: `${HELP_URL_TOP}/functionguide/report/#four-indicators`,
  CONTENT_NAME_REGISTRATION: `${HELP_URL_TOP}/functionguide/report/contentname/`,
  DATA_IMPORT: `${HELP_URL_TOP}/technique/dataimport/`,
  DOCUMENT_DOWNLOAD: `${HELP_URL_TOP}/document/`,
  NEWS_RELEASE: `${HELP_URL_TOP}/document/releasenotes/`,
  PAGE_REPORT_HOW_TO: `${HELP_URL_TOP}/functionguide/report/#pagereport`,
  CAPTURE_CONTROL_HOW_TO: `${HELP_URL_TOP}/functionguide/pagecapture/`,
  CROSS_DOMAIN_HOW_TO: `${HELP_URL_TOP}/functionguide/setting/#cross-domain`,
  DATA_EXPORT_HOW_TO: `${HELP_URL_TOP}/functionguide/dataexport/`,
  GET_CSS_SELECTOR_HOW_TO: `${HELP_URL_TOP}/technique/getcssselector/`,
  TARGET_CONTENT_HOW_TO: `${HELP_URL_TOP}/functionguide/report/targetcontent/`,
  GA4_EXCLUDE_CA_TRAFFIC_HOW_TO: `${HELP_URL_TOP}/technique/excludetraffic/`,
  CUSTOM_EVENT_GOAL_HOW_TO: `${HELP_URL_TOP}/technique/goalsettingevent/`,
  CRAWLER_IP_ADDRESS: `${HELP_URL_TOP}/startguide/system/`,
  FUNNEL_REPORT: `${HELP_URL_TOP}/functionguide/funnel_report/`,
  TRACKING_CODE_CONTROL_GTM_HOW_TO: `${HELP_URL_TOP}/technique/capturepagecontrol/`,
} as const

export const SUPPORT_FORM_LINK = 'https://www.uncovertruth.co.jp/contact-content-analytics/'

export const HELP_TEXTS = {
  SESSION_COUNT: '訪問した回数。３０分以上アクションがなければセッションは切れます',
  USER_COUNT: '訪問したユーザー数',
  SESSION_GOAL_COUNT: 'ページに訪問し、同一のセッション内で、プルダウンで選択中のゴールに到達したセッション数',
  USER_GOAL_COUNT: 'ページに訪問し、プルダウンで選択中のゴールに到達したユーザー数',
  SESSION_GOAL_RATE: 'ゴール数 ÷ セッション数',
  USER_GOAL_RATE: 'ゴール数 ÷ ユーザー数',
  SESSION_LANDING_COUNT:
    'セッションを開始した数\nセッション開始のアクセスが「集計期間」や「フィルタ」により除外された場合、ランディング数とセッション数は一致しません。',
  USER_LANDING_COUNT:
    'セッションを開始した数\nセッション開始のアクセスが「集計期間」や「フィルタ」により除外された場合、ランディング数とユーザー数は一致しません。',
  BOUNCE_RATE: '直帰数(※) ÷ ランディング数\n※1ページしか閲覧しなかったセッション数',
  SESSION_EXIT_RATE:
    '全体の離脱率 = 全体の離脱数(※) ÷ ページ別セッション数合計\nページ別の離脱率 = 離脱数(※) ÷ セッション数\n※セッションで最後のページになった数',
  USER_EXIT_RATE:
    '全体の離脱率 = 全体の離脱数(※) ÷ ページ別ユーザー数合計\nページ別の離脱率 = 離脱数(※) ÷ ユーザー数\n※セッションで最後のページになった数',
} as const

export const PAGE_TITLES = {
  PAGE_REPORT: 'レポート | ページ',
  CONTENT_REPORT: 'コンテンツレポート',
  CUSTOM_DIMENSION_REPORT: 'レポート | カスタムディメンション',
  FUNNEL_REPORTS: 'レポート | ファネル',
  FUNNEL_REPORT_EDIT: 'ファネルレポート編集',
  FUNNEL_REPORT_CREATE: 'ファネルレポート作成',
} as const
