import * as React from 'react'
import styled from 'styled-components'
import { FlagOutline } from '@styled-icons/evaicons-outline/FlagOutline'
import { PieChart } from '@styled-icons/boxicons-regular/PieChart'
import { Users } from '@styled-icons/heroicons-outline/Users'

import { colors, FONT_COLOR_MAIN, ICON_COLOR } from '../../../styleConstants'
import { getDateYYYYMMDDhhmi } from '../../../util/Date'

interface Props {
  capturedAt?: string
}

export function CaptureGuideBox({ capturedAt }: Props) {
  return (
    <Container>
      <Icons>
        <Icon>
          <Users size={16} color={ICON_COLOR} />
          <IconFont>ビュー数</IconFont>
        </Icon>
        <Icon>
          <FlagOutline size={16} color={ICON_COLOR} />
          <IconFont>
            ゴール
            <br />
            貢献数
          </IconFont>
        </Icon>
        <Icon>
          <PieChart size={16} color={ICON_COLOR} />
          <IconFont>
            ゴール
            <br />
            貢献率
          </IconFont>
        </Icon>
      </Icons>

      <CaptureDateBox>
        <CaptureDateValue>
          表示中のキャプチャ取得日：{capturedAt ? getDateYYYYMMDDhhmi(capturedAt) : '-'}
        </CaptureDateValue>
      </CaptureDateBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  background-color: ${colors.white};
  margin: 16px auto 0;
  width: 294px;
  height: 59px;
`

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 43px;
`

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 80px;

  & + & {
    margin-left: 0.5rem;
  }
`

const IconFont = styled.div`
  display: flex;
  align-items: center;
  color: ${ICON_COLOR};
  font-size: 10px;
`

const CaptureDateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  margin-bottom: 0.5rem;
`

const CaptureDateValue = styled.span`
  text-align: center;
  width: 100%;
  font-size: 10px;
  color: ${FONT_COLOR_MAIN};
`
