import * as React from 'react'
import styled from 'styled-components'
import { Users } from '@styled-icons/heroicons-outline/Users'
import { FlagOutline } from '@styled-icons/evaicons-outline/FlagOutline'
import { PieChart } from '@styled-icons/boxicons-regular/PieChart'

import { FONT_COLOR_SUB, ICON_COLOR } from '../../../styleConstants'
import { ContentLocation } from '../../../util/hooks/api/useContentReport'
import { TAB_INDEX, TabIndex } from '../../../pages/ContentReport/state'

interface Props {
  readonly location: ContentLocation
  readonly selectedTabIndex: TabIndex
}

export function CaptureTooltipContent({
  location: { name, viewCount, goalCount, goalRate, linkContentId },
  selectedTabIndex,
}: Props) {
  return (
    <Container>
      <ContentName>
        {selectedTabIndex === TAB_INDEX.CONTENT_GROUP && <GroupNo>{linkContentId}</GroupNo>}
        {name}
      </ContentName>
      <IconsArea>
        <Box>
          <Users size={24} color={ICON_COLOR} />
          <Font>{viewCount}</Font>
        </Box>
        <Box>
          <FlagOutline size={24} color={ICON_COLOR} />
          <Font>{goalCount}</Font>
        </Box>
        <Box>
          <PieChart size={24} color={ICON_COLOR} />
          <Font>{goalRate.toFixed(2)}%</Font>
        </Box>
      </IconsArea>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 168px;
`

const ContentName = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${FONT_COLOR_SUB};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const GroupNo = styled.span`
  padding-right: 4px;
`

const IconsArea = styled.div`
  display: flex;
  flex-flow: row;
  padding: 4px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 44px;
  width: 56px;
`

const Font = styled.div`
  color: ${FONT_COLOR_SUB};
  font-size: 12px;
`
