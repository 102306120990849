export const colors = {
  black: '#000000',
  black2: '#121212',
  gray900: '#212121',
  gray800: '#424242',
  gray750: '#666666',
  gray700: '#616161',
  gray650: '#707070',
  gray600: '#757575',
  gray550: '#8E8E8E',
  gray500: '#9E9E9E',
  gray400: '#BDBDBD',
  gray350: '#EBE8E8',
  gray325: '#E8E8E8',
  gray300: '#E0E0E0',
  gray250: '#CCCCCC',
  gray200: '#EEEEEE',
  gray100: '#F5F5F5',
  gray50: '#FAFAFA',
  white: '#FFFFFF',
  lightBlue: '#87B9CE',
  darkBlue: '#142830',
  lightCyan: '#D7E8EF',
  lightSalmon: '#F9E4DB',
  error: '#DB0000',
  errorBg: '#FCEAEA',
  success: '#008F28',
  bg: '#EBF3F7',
  orange: '#DC5F25',
  red: '#CB3F26',
  headerBg: '#3B7891',
  peachPuff: '#F3CAB6',
  blue: '#9bc5d6',
  blue2: '#92B6E5',
  yellow: '#FBDA79',
  yellow2: '#FDF3CD',
  lightYellow: '#FFFFE0',
  green: '#78CF9D',
  headerTitle: '#B1C9D3',
  disabled: '#8E8E8E',
  purple: '#8268A0',
  datePickerBorderWhite: '#e5e5ea',
  link: {
    base: '#3B7891',
    visited: '#8268A0',
    hover: '#285061',
  },
  contentBlue: {
    blue1: '#EBF3F7', // bg
    blue2: '#D7E8EF',
    blue3: '#9BC5D6',
    blue4: '#87B9CE',
    blue5: '#579DBA',
    blue6: '#3B7891',
    blue7: '#285061',
    blue8: '#142830',
    blue9: '#2169C7',
    blue10: '#3C7891',
  },
  contentOrange: {
    orange1: '#F9E4DB',
    orange2: '#F3CAB6',
    orange3: '#EEAF92',
    orange4: '#E8946E',
    orange5: '#E27A49',
    orange6: '#DC5F25', // base
    orange7: '#943F18',
    orange8: '#4A1F0C',
  },
  opacity: {
    100: 'FF', // Adobe XD上の不透明度「100%」 (濃)
    90: 'E6', // Adobe XD上の不透明度「90%」
    80: 'CC', // Adobe XD上の不透明度「80%」
    70: 'B3', // Adobe XD上の不透明度「70%」
    60: '99', // Adobe XD上の不透明度「60%」
    50: '80', // Adobe XD上の不透明度「50%」
    40: '66', // Adobe XD上の不透明度「40%」
    30: '4D', // Adobe XD上の不透明度「30%」
    20: '33', // Adobe XD上の不透明度「20%」
    10: '1A', // Adobe XD上の不透明度「10%」
    0: '00', // // Adobe XD上の不透明度「0%」 (薄)
  },
}

export const FONT_COLOR_MAIN = colors.black2
export const FONT_COLOR_SUB = colors.gray750
export const ICON_COLOR = colors.gray550
export const DATA_BAR_COLORS = {
  COUNT: colors.green + colors.opacity[40],
  RATE: colors.yellow + colors.opacity[40],
  VIEW_SECOND: colors.blue2 + colors.opacity[40],
  FUNNEL_REPORT_COUNT: colors.contentBlue.blue5,
}
export type DataBarColorType = typeof DATA_BAR_COLORS[keyof typeof DATA_BAR_COLORS]

export const style = {
  dark: 'rgba(20, 40, 48, 0.8)',
  shadow: 'rgba(0, 0, 0, 0.2)',
}

export const layout = {
  baseWidth: '400px',
  boldFontWeight: '600',
  pageMaxWidth: '1400px',
  pageMinWidth: '1210px',
  settingPageWidth: '1050px',
  dataImportPageWidth: '1136px',
  headerHeight: '80px',
  headerHeightNumber: '80',
  footerHeight: '30px',
  reportMenuHeight: '48px',
  reportHeaderHeight: '128px', // headerHeight + reportMenuHeight
  navigationWidth: '240px',
  navigationDevMargin: '40px',
  filterWidth: '220px',
  navigationCloseWidth: '48px',
  funnelReportRegisterFooterZIndex: 50,
  navigationBackdropZIndex: 102,
  navigationZIndex: 103,
  popupZIndex: 104,
  modalBackdropZIndex: 105,
  modalZIndex: 106,
  modalLoadingZIndex: 107,
  modalCloseButtonZIndex: 108,
}

export const adjust = {
  naviOpenHeight: '153px',
  naviCloseHeight: '65px',
}

export const GRID_BORDER_COLOR = colors.gray250
