import { useCookies } from 'react-cookie'

export const CAPTURE_VIEW = {
  WITH_GOAL_RATE: 'WITH_GOAL_RATE',
  WITH_BORDER: 'WITH_BORDER',
  WITHOUT_BORDER: 'WITHOUT_BORDER',
} as const
export type CaptureView = keyof typeof CAPTURE_VIEW

interface Result {
  readonly captureView: CaptureView
  readonly setCaptureView: (value: CaptureView) => void
}

const COOKIE_KEY = `captureView`

export const useCaptureView = (): Result => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY])

  const captureView = cookies[COOKIE_KEY] || CAPTURE_VIEW.WITH_GOAL_RATE

  const setCaptureView = (newCaptureView: CaptureView) => {
    const validatedNewCaptureView = validateCaptureView(newCaptureView)
    if (!validatedNewCaptureView) return
    setCookie(COOKIE_KEY, validatedNewCaptureView, { path: '/' })
  }

  return { captureView, setCaptureView }
}

const validateCaptureView = (captureView: string | null): CaptureView | null => {
  const castCaptureView = captureView as CaptureView
  if (!Object.values(CAPTURE_VIEW).includes(castCaptureView)) {
    return null
  }
  return castCaptureView
}
