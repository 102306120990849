import * as React from 'react'
import styled from 'styled-components'
import { ModeEdit } from '@styled-icons/material-outlined/ModeEdit'
import { Tag } from '@styled-icons/boxicons-regular'

import { colors, layout } from '../../../../styleConstants'
import { GlobalContext } from '../../../../GlobalState'
import { Authority } from '../../../../util/Authority'
import { Actions, State } from '../../../../pages/ContentReport/state'
import { SubActionIconButton } from '../../../common/Button'
import { WarningArea } from '../../WarningArea'
import { AllContentsGrid } from './AllContentsGrid'
import { SortItemKey, useContentReport } from '../../../../util/hooks/api/useContentReport'
import { Loading } from '../../../common/Loading'
import { sortBy, SortOrder } from '../../../../util/sortBy'
import { extractContentReportSortValue } from '../..'
import { REGISTER_FOOTER_HEIGHT } from '../..'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../../contexts/CustomFilterContext'

interface Props {
  readonly projectId: string
  pageState: State
  actions: Actions
  readonly setAllContentsDisplayHistoryId: (newValue: number) => void
  readonly reportId: string
}
export function AllContentsTab({ projectId, pageState, actions, setAllContentsDisplayHistoryId, reportId }: Props) {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const {
    state: { calenderState },
  } = React.useContext(ReportContext)

  const {
    allContentsState: { errorMessage, sortState },
    selectedContentIds,
    gridScrollTo,
  } = pageState

  const { data, isError, isLoading } = useContentReport({
    projectId,
    reportId,
    calenderState,
    customFilterState,
    sortState,
  })

  const { sortKey, order } = React.useMemo((): { sortKey: SortItemKey | null; order: SortOrder } => {
    return extractContentReportSortValue(sortState)
  }, [sortState])

  if (isLoading) return <Loading />
  if (isError) return <WarningArea message={errorMessage} />

  const handleHeaderCheckboxChange = () => {
    actions.onHeaderCheckboxChange(data.contents)
  }

  const handleItemClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const content = data.contents.find((item) => item.id === Number(e.currentTarget.dataset.id))
    const history = data.pageHistories.find((history) => history.id === content?.linkPageHistoryId)
    if (content && history) {
      if (e.shiftKey) {
        document.getSelection()?.removeAllRanges()
        actions.onItemCheckboxSelected(content)
      } else {
        actions.onItemSelected(content)
      }
      setAllContentsDisplayHistoryId(history.id)
    }
  }

  const handleItemCheck: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    const content = data.contents.find((item) => item.id === Number(e.currentTarget.dataset.id))
    const history = data.pageHistories.find((history) => history.id === content?.linkPageHistoryId)
    if (content && history) {
      actions.onItemCheckboxSelected(content)
      setAllContentsDisplayHistoryId(history.id)
    }
  }

  return (
    <>
      {errorMessage && <WarningArea message={errorMessage} />}

      <AllContentsGrid
        projectId={projectId}
        pageId={data.pageId}
        calenderState={calenderState}
        pageSummary={data.pageSummary}
        items={sortKey ? sortBy(data.contents, sortKey, order) : data.contents}
        contentsSummary={data.contentsSummary}
        selectedIds={selectedContentIds}
        sortState={sortState}
        gridScrollTo={gridScrollTo}
        canViewPartialContent={Authority.canViewPartialContent(AccountInfo.permissions, projectId!)}
        onClick={handleItemClick}
        onCheckboxClick={handleItemCheck}
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        onSort={(key) => {
          actions.onContentGridSort(key)
        }}
        onReset={actions.onGridStateReset}
        onEdit={actions.regist.onEdit}
      />
      <RegisterFooter visible={!!selectedContentIds.length}>
        <RegisterText>選択中のコンテンツをまとめて</RegisterText>
        <SubActionIconButton onClick={actions.onEditMultipleTags} iconLeft={<Tag size="16" />}>
          タグ編集
        </SubActionIconButton>
        <SubActionIconButton onClick={actions.onEditMultipleContent} iconLeft={<ModeEdit size="16" />}>
          コンテンツ名編集
        </SubActionIconButton>
      </RegisterFooter>
    </>
  )
}

const RegisterFooter = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: ${({ visible }) => (visible ? 0 : '-80px')};
  left: 0;
  width: 100%;
  height: ${REGISTER_FOOTER_HEIGHT}px;
  padding: 0 32px;
  background-color: ${colors.contentBlue.blue8};
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  transition-duration: 0.3s;
  z-index: ${layout.popupZIndex - 1};
`

const RegisterText = styled.div`
  color: ${colors.white};
  font-size: 12px;
`
