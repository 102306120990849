import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../useDeviceType'
import { CalenderState } from '../../../components/common/DayPickerRange'
import { SORT_ICONS, SortIconState } from '../../../components/common/SortButton'
import { CustomFilterState, ReportFilter } from './Filter/types'
import { ContentReportResponse } from '../../responses/ContentReportResponse'
import { request } from '../../request'
import { Percent } from '../../Parse'
import { makeReportFilters } from '../../makeReportFilters'
import { toUpperSnakeCase } from '../../toSnakeCase'
import { getConversionRateGrade, RateGrade } from '../../getConversionRateGrade'
import { sortBy } from '../../sortBy'
import { QueryFilters, useQuery, useQueryClient } from '@tanstack/react-query'
import { CONTENT_REPORT_QUERY_KEY } from './constants'
import { getDateStringYMD } from '../../Date'
import { getScopeTypeApiValue, ScopeTypeApiValue } from '../../getScopeTypeApiValue'
import { getScaledPercent } from '../../getScaledPercent'
import { ScopeType, useScopeType } from '../useScopeType'
import { useFilterContentEventsExists } from '../cookie/useFilterContentEventsExists'
import { useGoalList } from './Goal/useGoalList'
import { useGoalId } from '../useGoalId'

export type ContentScreenshot = {
  id: number
  imageUrl: string
  cssSelector: string
  targetTypeName: string
  capturedAt: string
}

export type ContentTag = {
  id: number
  name: string
}

export type Content = {
  id: number
  name: string
  isAutoSetName: boolean
  linkPageHistoryId: number // 表エリアのコンテンツクリックと連動してキャプチャ側で表示するページ更新履歴ID
  linkContentLocationId: number // 表エリアのコンテンツクリックと連動してキャプチャ側で選択するコンテンツ表示位置ID
  viewCount: number
  viewCountScaledPercent: number
  viewRate: number
  viewRateScaledPercent: number
  viewSecond: number
  viewSecondScaledPercent: number
  goalCount: number
  goalCountScaledPercent: number
  goalRate: number
  goalRateScaledPercent: number
  goalRateRank: number
  goalGrade: RateGrade
  clickCount: number
  clickCountScaledPercent: number
  clickRate: number
  clickRateScaledPercent: number
  measurementStartedDate: string
  measurementEndedDate: string | null
  contentScreenshot: ContentScreenshot
  tags: ContentTag[]
}

const SORT_ITEM_KEYS = [
  'id',
  'viewCount',
  'viewRate',
  'viewSecond',
  'goalCount',
  'goalRate',
  'clickCount',
  'clickRate',
] as const

export type SortItemKey = typeof SORT_ITEM_KEYS[number]

export type SortState = {
  [K in SortItemKey]: SortIconState
}

interface SortParams {
  [key: string]: string
}
const SORT_PARAMS: SortParams = {
  ID: 'id',
  VIEW_COUNT: 'valid_views',
  VIEW_RATE: 'valid_views_rate',
  VIEW_SECOND: 'second',
  GOAL_COUNT: 'goal_contributions',
  GOAL_RATE: 'goal_contributions_rate',
  CLICK_COUNT: 'clicks',
  CLICK_RATE: 'clicks_rate',
} as const

export type ContentLocation = {
  id: number
  linkContentId: number // キャプチャエリアのコンテンツクリックと連動して表側で選択するコンテンツID
  topLeftX: number
  topLeftY: number
  width: number
  height: number
  name: string
  viewCount: number
  goalCount: number
  goalRate: number
  goalRateRank: number
  goalGrade: RateGrade
}

export type PageHistory = {
  id: number
  capturedAt: string
  screenshotUrl: string
  screenshotWidth: number
  contentLocations: ContentLocation[]
  downloadFilename: string
}

export type PageSummary = {
  sessions: number
  users: number
  conversions: number
  conversionsRate: number
}

export type ContentsSummary = {
  viewCount: number
  viewRate: number
  viewSecond: number
  goalCount: number
  goalRate: number
  clickCount: number
  clickRate: number
}

export type ContentReportData = {
  pageId: number
  urlId: number
  url: string
  urlTitle: string
  isCaptureFailed: boolean
  pageSummary: PageSummary
  contentsSummary: ContentsSummary
  contents: Content[]
  pageHistories: PageHistory[]
  displayPageHistoryId: number
}

type RequestParam = {
  goal_id: number
  page_layout: number
  search_from: string
  search_to: string
  scope_type: ScopeTypeApiValue
  filters?: Array<ReportFilter>
  sort_key?: string
  sort_reverse?: number
}

type SortParam = {
  key: string
  reverse: 0 | 1
}

export const useContentReport = ({
  projectId,
  reportId,
  calenderState,
  customFilterState,
  sortState,
  enabled = true,
}: {
  projectId: string
  reportId: string
  calenderState: CalenderState
  customFilterState: CustomFilterState[]
  sortState: SortState
  enabled?: boolean
}) => {
  const queryClient = useQueryClient()
  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId: Number(projectId) })
  const { filterContentEventsExists } = useFilterContentEventsExists()

  const { getGoal } = useGoalList({ projectId: Number(projectId) })
  const goal = getGoal(goalId)

  const queryKey = [
    CONTENT_REPORT_QUERY_KEY,
    {
      projectId,
      reportId,
      deviceType,
      goal,
      startDate: calenderState.startDate,
      endDate: calenderState.endDate,
      scopeType,
      filterContentEventsExists,
      customFilterState,
    },
  ]

  const setContentReport = (
    updater:
      | ContentReportData
      | undefined
      | ((oldData: ContentReportData | undefined) => ContentReportData | undefined),
  ) => queryClient.setQueryData<ContentReportData>(queryKey, updater)

  const invalidateContentReport = async (queryFilters?: Omit<QueryFilters, 'queryKey'>) => {
    await queryClient.invalidateQueries({ queryKey: [CONTENT_REPORT_QUERY_KEY], ...queryFilters })
  }
  const resetContentReport = async (queryFilters?: Omit<QueryFilters, 'queryKey'>) => {
    await queryClient.removeQueries({ queryKey: [CONTENT_REPORT_QUERY_KEY], ...queryFilters })
  }

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const data = await request<ContentReportResponse>(
        'POST',
        `/api/projects/${projectId}/page_reports/${reportId}/`,
        true,
        getRequestBody(
          getDeviceLayoutNumber(deviceType),
          goalId,
          calenderState,
          scopeType,
          filterContentEventsExists,
          customFilterState,
          sortState,
        ),
      )
      const contents = makeContents(data)
      const pageHistories = makePageHistories(data, contents)
      const formatted: ContentReportData = {
        pageId: data.page_id,
        urlId: data.url_id,
        url: data.url,
        urlTitle: data.title,
        isCaptureFailed: data.is_last_failed_cs,
        pageSummary: {
          sessions: data.page_sessions,
          users: data.page_users,
          conversions: data.page_conversions,
          conversionsRate: Percent.parse(data.page_conversions_rate),
        },
        contentsSummary: {
          viewCount: data.page_valid_views,
          viewRate: Percent.parse(data.page_valid_views_rate),
          viewSecond: data.page_second,
          goalCount: data.page_goal_contributions,
          goalRate: Percent.parse(data.page_goal_contributions_rate),
          clickCount: data.page_clicks,
          clickRate: Percent.parse(data.page_clicks_rate),
        },
        displayPageHistoryId: pageHistories[0]?.id,
        contents,
        pageHistories,
      }
      return formatted
    },
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 5,
    enabled: enabled && !!goal,
  })
  return {
    ...queryResult,
    setContentReport,
    invalidateContentReport,
    resetContentReport,
  }
}

export interface GetContentReportDataProps {
  readonly projectId: string
  readonly pageReportId: string
  readonly goalId: number
  readonly calenderState: CalenderState
  readonly customFilterState: CustomFilterState[]
  readonly device: DeviceType
  readonly sortState: SortState
}

const makeContents = (response: ContentReportResponse): Array<Content> => {
  if (response.contents.length === 0) {
    return []
  }
  const goalRateRankList = [
    ...new Set(sortBy(response.contents, 'goal_contributions_rate').map((content) => content.goal_contributions_rate)),
  ]

  const minViewCount: number = Math.min(...response.contents.map((content) => content.valid_views))
  const maxViewCount: number = Math.max(...response.contents.map((content) => content.valid_views))
  const minViewRate: number = Math.min(...response.contents.map((content) => content.valid_views_rate))
  const maxViewRate: number = Math.max(...response.contents.map((content) => content.valid_views_rate))
  const minViewSecond: number = Math.min(...response.contents.map((content) => content.second))
  const maxViewSecond: number = Math.max(...response.contents.map((content) => content.second))
  const minGoalCount: number = Math.min(...response.contents.map((content) => content.goal_contributions))
  const maxGoalCount: number = Math.max(...response.contents.map((content) => content.goal_contributions))
  const minGoalRate: number = Math.min(...response.contents.map((content) => content.goal_contributions_rate))
  const maxGoalRate: number = Math.max(...response.contents.map((content) => content.goal_contributions_rate))
  const minClickCount: number = Math.min(...response.contents.map((content) => content.clicks))
  const maxClickCount: number = Math.max(...response.contents.map((content) => content.clicks))
  const minClickRate: number = Math.min(...response.contents.map((content) => content.clicks_rate))
  const maxClickRate: number = Math.max(...response.contents.map((content) => content.clicks_rate))

  return response.contents.map((content) => {
    const goalRate = Percent.parse(content.goal_contributions_rate)
    const pageGoalRate = Percent.parse(response.page_conversions_rate)

    return {
      id: content.id,
      name: !content.managed_content ? '' : content.managed_content.name,
      isAutoSetName: content.managed_content?.is_auto_set ?? false,
      contentScreenshot: {
        id: content.content_screenshot.id,
        imageUrl: content.content_screenshot.image_url,
        cssSelector: content.content_screenshot.css_selector,
        targetTypeName: content.content_screenshot.target_type_name,
        capturedAt: content.content_screenshot.created_at,
      },
      linkPageHistoryId: content.page_history_id,
      linkContentLocationId: content.content_location_id,
      viewCount: content.valid_views,
      viewCountScaledPercent: getScaledPercent(content.valid_views, minViewCount, maxViewCount),
      viewRate: Percent.parse(content.valid_views_rate),
      viewRateScaledPercent: getScaledPercent(content.valid_views_rate, minViewRate, maxViewRate),
      viewSecond: content.second,
      viewSecondScaledPercent: getScaledPercent(content.second, minViewSecond, maxViewSecond),
      goalCount: content.goal_contributions,
      goalCountScaledPercent: getScaledPercent(content.goal_contributions, minGoalCount, maxGoalCount),
      goalRate: Percent.parse(content.goal_contributions_rate),
      goalRateScaledPercent: getScaledPercent(content.goal_contributions_rate, minGoalRate, maxGoalRate),
      goalRateRank: goalRateRankList.findIndex((rate) => rate === content.goal_contributions_rate) + 1,
      goalGrade: getConversionRateGrade(goalRate, pageGoalRate),
      clickCount: content.clicks,
      clickCountScaledPercent: getScaledPercent(content.clicks, minClickCount, maxClickCount),
      clickRate: Percent.parse(content.clicks_rate),
      clickRateScaledPercent: getScaledPercent(content.clicks_rate, minClickRate, maxClickRate),
      measurementStartedDate: content.measurement_started_at,
      measurementEndedDate: content.measurement_ended_at,
      tags: content.content_tags,
    }
  })
}

const makePageHistories = (response: ContentReportResponse, contents: Content[]): Array<PageHistory> => {
  if (response.page_history.length === 0) {
    return []
  }
  return response.page_history.map((history) => {
    return {
      id: history.id,
      capturedAt: history.created_at,
      screenshotUrl: history.screenshot_url,
      screenshotWidth: history.screenshot_width,
      contentLocations: history.content_locations.map((location) => {
        const content = contents.find((content) => content.id === location.partial_content_id)
        return {
          id: location.id,
          linkContentId: location.partial_content_id,
          topLeftX: location.top_left_x,
          topLeftY: location.top_left_y,
          width: location.width,
          height: location.height,
          name: content?.name || '',
          viewCount: content?.viewCount || 0,
          goalCount: content?.goalCount || 0,
          goalRate: content?.goalRate || 0,
          goalRateRank: content?.goalRateRank || 0,
          goalGrade: content?.goalGrade || getConversionRateGrade(0, 0),
        }
      }),
      downloadFilename: history.download_filename,
    }
  })
}

export function makeRequestJson(
  pageLayout: number,
  goalId: number,
  calenderState: CalenderState,
  scopeType: ScopeType,
  filterContentEventsExists: boolean,
  customFilterState: CustomFilterState[],
  sortState?: SortState,
): RequestParam {
  let json = {
    goal_id: goalId,
    page_layout: pageLayout,
    search_from: getDateStringYMD(calenderState.startDate, '-'),
    search_to: getDateStringYMD(calenderState.endDate, '-'),
    scope_type: getScopeTypeApiValue(scopeType),
    filters: makeReportFilters(customFilterState),
    filter_content_events_exists: filterContentEventsExists,
  }

  // ソート
  if (sortState) {
    const sortParam = getSortParam(sortState)
    if (sortParam !== null) {
      const sort = { sort_key: sortParam.key, sort_reverse: sortParam.reverse }
      json = { ...json, ...sort }
    }
  }

  return json
}

export function getRequestBody(
  pageLayout: number,
  goalId: number,
  calenderState: CalenderState,
  scopeType: ScopeType,
  filterContentEventsExists: boolean,
  customFilterState: CustomFilterState[],
  sortState?: SortState,
) {
  return JSON.stringify(
    makeRequestJson(
      pageLayout,
      goalId,
      calenderState,
      scopeType,
      filterContentEventsExists,
      customFilterState,
      sortState,
    ),
  )
}

export function getSortParam(state: SortState): SortParam | null {
  const currentSortKey = SORT_ITEM_KEYS.find((itemKey) => state[itemKey] !== SORT_ICONS.NONE)
  return currentSortKey
    ? {
        key: SORT_PARAMS[toUpperSnakeCase(currentSortKey)],
        reverse: state[currentSortKey] === SORT_ICONS.UP ? 0 : 1,
      }
    : null
}

export const makeErrorMessage = (data: ContentReportData, filterStates: CustomFilterState[]): string => {
  if (data.contents.length > 0) {
    return ''
  }
  if (filterStates.length > 0) {
    return 'ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。'
  }
  return '検索対象期間にはデータがありません。'
}
