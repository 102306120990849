import React from 'react'
import styled from 'styled-components'
import Tippy from '@tippyjs/react'
import HeadlessTippy from '@tippyjs/react/headless'
import { colors, layout, style } from '../../styleConstants'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import { Placement } from 'tippy.js'

export const TOOLTIP_DELAY: [number, number] = [800, 0] // [マウスホバー後の表示開始までの遅延ms, マウスアウト後の表示終了までの遅延ms]

interface Props {
  readonly id?: string
  readonly content?: React.ReactNode
  readonly children?: React.ReactElement<any>
  readonly borderColor?: string
}

export function Tooltip(props: Props) {
  const { id, content, children, borderColor = 'rgba(0,0,0,0.5)' } = props
  return (
    <DesignedTippy className={`DesignedTippy-${id}`} content={content} theme="light-border" borderColor={borderColor}>
      {children}
    </DesignedTippy>
  )
}

const DesignedTippy = styled(Tippy)<{ borderColor: string }>`
  &.tippy-box[data-theme~='light-border'] {
    border-style: solid;
    border-width: 1px;
    border-color: ${(props) => props.borderColor};
  }

  &.tippy-box[data-theme~='light-border'][data-placement^='top'] > .tippy-arrow::after {
    border-top-color: ${(props) => props.borderColor};
  }

  &.tippy-box[data-theme~='light-border'][data-placement^='bottom'] > .tippy-arrow::after {
    border-bottom-color: ${(props) => props.borderColor};
  }

  &.tippy-box[data-theme~='light-border'][data-placement^='left'] > .tippy-arrow::after {
    border-left-color: ${(props) => props.borderColor};
  }

  &.tippy-box[data-theme~='light-border'][data-placement^='right'] > .tippy-arrow::after {
    border-right-color: ${(props) => props.borderColor};
  }
`

interface InteractiveTooltipProps {
  readonly content?: React.ReactNode
  readonly children?: React.ReactElement<any>
  readonly disabled?: boolean
  readonly delay?: [number, number] // [マウスホバー後の表示開始までの遅延ms, マウスアウト後の表示終了までの遅延ms]
  readonly placement?: Placement
  readonly offset?: [number, number]
}

export function InteractiveTooltip({
  content,
  children,
  disabled,
  delay = [0, 0],
  placement = 'bottom-start',
  offset = [0, 10],
}: InteractiveTooltipProps) {
  return (
    <HeadlessTippy
      delay={delay}
      interactive={true}
      disabled={disabled}
      placement={placement}
      offset={offset}
      render={() => (
        <Popup
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {content}
        </Popup>
      )}
    >
      {children}
    </HeadlessTippy>
  )
}

const Popup = styled.div`
  width: auto;
  max-width: 600px;
  height: auto;
  padding: 6px;
  margin-top: -10px;
  font-size: 10px;
  color: ${colors.white};
  background-color: ${style.dark};
  box-shadow: 0 0 4px ${colors.gray400};
  z-index: ${layout.popupZIndex};
  word-wrap: break-word;
`
