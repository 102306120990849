// レポート画面で各アイテムのviewCount, goalCountなどをレポート内全ての値の最小を0、最大値を100としたスケールで計算
// 例: レポート内の全てのviewCountの最小値20, 最大値:120, 計算するviewCount:45の場合、
// scaledPercent: ((45 - 20) / (120 - 20)) * 100 = 25% となる
export const getScaledPercent = (count: number, minCount: number, maxCount: number): number => {
  if (count === 0) return 0

  // 値が同じ場合はデータバー100%表示。参考issue: https://github.com/uncovertruth/content-analytics/issues/2121#issuecomment-1849355816
  if (minCount === maxCount) return 100

  // 最小値のバー横幅が0pxにならないよう、最小値が0以上ならば横幅2%だけ出るように調整
  const adjustMinWidth = (maxCount - minCount) * 0.02
  const adjustMinCount = minCount - adjustMinWidth

  return Math.round(((count - adjustMinCount) / (maxCount - adjustMinCount)) * 100)
}
