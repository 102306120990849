import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { Tooltip } from '../../Tooltip'
import { OnSelectProps, TAB_INDEX, TabIndex } from '../../../pages/ContentReport/state'
import { getBorderWidthByRateGrade, getColorByRateGrade, RateGrade } from '../../../util/getConversionRateGrade'
import { ContentLocation } from '../../../util/hooks/api/useContentReport'
import { CaptureTooltipContent } from './CaptureTooltipContent'
import { CaptureGroupNumber } from './CaptureGroupNumber'
import { RankLabel } from '../RankLabel'
import { LOWEST_RANK_TO_SHOW } from '..'
import { CAPTURE_VIEW, CaptureView, useCaptureView } from '../../../util/hooks/useCaptureView'

interface Props {
  readonly location: ContentLocation
  readonly ratio: number
  readonly selected: boolean
  readonly contentScrollTo: number | null
  readonly onSelect: (props: OnSelectProps) => void
  readonly scrollContent: (y: number) => void
  readonly onResetScroll: () => void
  readonly selectedTabIndex: TabIndex
  readonly pageCaptureAreaWidth: number | null
}

export function CaptureContentLocationBox({
  location,
  ratio,
  selected,
  contentScrollTo,
  onSelect,
  scrollContent,
  onResetScroll,
  selectedTabIndex,
  pageCaptureAreaWidth,
}: Props) {
  const { captureView } = useCaptureView()

  React.useEffect(() => {
    if (contentScrollTo !== null && contentScrollTo === location.linkContentId) {
      scrollContent(location.topLeftY * ratio)
      onResetScroll()
    }
  }, [contentScrollTo])

  const onClickContent = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onSelect({ ids: [location.linkContentId], shiftKeyPressing: event.shiftKey, selected })
  }

  const rankVisible =
    captureView === CAPTURE_VIEW.WITH_GOAL_RATE &&
    location.goalRate !== 0 &&
    location.goalRateRank <= LOWEST_RANK_TO_SHOW

  return (
    <Container
      id={`${location.linkContentId}-box`}
      key={`${location.linkContentId}-key`}
      data-key={location.linkContentId}
      className={'selectable'}
      ratio={ratio}
      width={location.width}
      height={location.height}
      x={location.topLeftX}
      y={location.topLeftY}
      selected={selected}
      onClick={onClickContent}
    >
      <Tooltip content={CaptureTooltipContent({ location, selectedTabIndex })} borderColor="none">
        <TooltipItem
          id={`${location.linkContentId}`}
          ratio={ratio}
          width={location.width}
          height={location.height}
          selected={selected}
          captureView={captureView}
          grade={location.goalGrade}
        >
          <LabelArea selectedTabIndex={selectedTabIndex}>
            {selectedTabIndex === TAB_INDEX.CONTENT_GROUP && captureView !== CAPTURE_VIEW.WITHOUT_BORDER && (
              <CaptureGroupNumber location={location} pageCaptureAreaWidth={pageCaptureAreaWidth} />
            )}
            {rankVisible && (
              <RankLabel
                rank={location.goalRateRank}
                isLarge={selectedTabIndex === TAB_INDEX.CONTENT_GROUP}
                backgroundColor={colors.yellow}
              />
            )}
          </LabelArea>
        </TooltipItem>
      </Tooltip>
    </Container>
  )
}

type ContainerProps = {
  ratio: number
  width: number
  height: number
  x: number
  y: number
  selected: boolean
}
const Container = styled.div<ContainerProps>`
  position: absolute;
  width: ${({ width, ratio }) => `${width * ratio}px`};
  height: ${({ height, ratio }) => `${height * ratio}px`};
  top: ${({ y, ratio }) => `${y * ratio}px`};
  left: ${({ x, ratio }) => `${x * ratio}px`};
  right: ${({ x, width, ratio }) => `${(x + width) * ratio}px`};
  bottom: ${({ y, height, ratio }) => `${(y + height) * ratio}px`};
  cursor: pointer;
  z-index: ${({ selected }) => (selected ? 21 : 20)};
  pointer-events: auto;
`

type TooltipItemProps = {
  ratio: number
  width: number
  height: number
  selected: boolean
  captureView: CaptureView
  grade: RateGrade
}
const TooltipItem = styled.div<TooltipItemProps>`
  position: absolute;
  z-index: 100;
  width: ${({ width, ratio }) => `${width * ratio}px`};
  height: ${({ height, ratio }) => `${height * ratio}px`};
  background-color: ${({ selected }) => {
    return selected ? '#CB3F2633' : ''
  }};
  border-style: ${({ captureView }) => {
    return captureView === CAPTURE_VIEW.WITHOUT_BORDER ? 'none' : 'solid'
  }};
  border-color: ${({ captureView, grade }) => {
    return captureView === CAPTURE_VIEW.WITH_GOAL_RATE ? getColorByRateGrade(grade) : colors.contentOrange.orange5
  }};
  border-width: ${({ captureView, grade }) => {
    return captureView === CAPTURE_VIEW.WITH_GOAL_RATE ? getBorderWidthByRateGrade(grade) : '2px'
  }};
`

const LabelArea = styled.div<{ selectedTabIndex: TabIndex }>`
  display: flex;
  height: 100%;
  max-height: ${({ selectedTabIndex }) => (selectedTabIndex === TAB_INDEX.CONTENT_GROUP ? '30px' : '20px')};
  width: 100%;
`
