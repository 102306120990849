import * as React from 'react'
import styled from 'styled-components'
import { getColorByRateGrade, getFontWeightByRateGrade, RateGrade } from '../../../util/getConversionRateGrade'
import { LOWEST_RANK_TO_SHOW } from '..'
import { BaseCell } from './BaseCell'
import { colors, DATA_BAR_COLORS } from '../../../styleConstants'
import { RateValue } from '../../values/RateValue'
import { CrownIcon } from '../../icons/CrownIcon'

interface Props {
  readonly dataId: number
  readonly goalGrade: RateGrade
  readonly goalRate: number
  readonly goalRateRank: number
  readonly barPercent: number
}

export function GoalRateCell({ dataId, goalRate, goalGrade, goalRateRank, barPercent }: Props) {
  const rankVisible = goalRate !== 0 && goalRateRank <= LOWEST_RANK_TO_SHOW
  return (
    <Cell data-id={dataId} rankVisible={rankVisible} barPercent={barPercent} barColor={DATA_BAR_COLORS.RATE}>
      {rankVisible && <RankIcon rank={goalRateRank} />}
      <GoalRate grade={goalGrade}>
        <RateValue value={goalRate} digit={2} />
      </GoalRate>
    </Cell>
  )
}

function RankIcon({ rank }: { rank: number }) {
  return (
    <CrownContainer>
      <CrownIcon size={28} color={`${colors.red}`} />
      <RankOverlay>{rank}</RankOverlay>
    </CrownContainer>
  )
}

const CrownContainer = styled.div`
  position: relative;
  display: inline-block;
`

const RankOverlay = styled.span`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${colors.white};
  font-size: 10px;
  font-weight: bold;
`

const Cell = styled(BaseCell)<{ rankVisible: boolean }>`
  justify-content: ${({ rankVisible }) => (rankVisible ? 'space-between' : 'flex-end')};
`
const GoalRate = styled('div')<{ grade: RateGrade }>`
  color: ${({ grade }) => getColorByRateGrade(grade)};
  font-weight: ${({ grade }) => getFontWeightByRateGrade(grade)};
`
